import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import { useSearchParams } from 'router'

import { priceChannels } from 'modules/priceSelection'
import { useUser } from 'modules/user'

import { type UserGender } from 'typings/graphql'
import privateSaleQuery, { type PrivateSalePayload } from './graph/privateSale.graphql'


type PrivateSaleList = PrivateSalePayload['flashSaleList']['data']

export type PrivateSaleItem = PrivateSaleList[number] & {
  originalPrice: number
}

const usePrivateSale = () => {
  const { isLoggedIn, gender: userGender, country } = useUser()
  const [ { gender: queryGender = '' } ] = useSearchParams()

  const { data, isFetching } = useQuery(privateSaleQuery, {
    skip: !isLoggedIn,
    variables: {
      input: {
        priceSelection: {
          country,
          channel: priceChannels.privateSale,
        },
      },
    },
  })

  // you can override main gender by query param `?gender`
  const gender = queryGender.toUpperCase() || userGender

  const { privateSaleList, privateSale } = useMemo(() => {
    if (!data?.flashSaleList?.data?.length) {
      return {}
    }

    const privateSaleList: PrivateSaleList = data.flashSaleList.data


    const salesMap = privateSaleList.reduce((result, sale) => {
      result[sale.gender] = {
        ...sale,
        originalPrice: sale.price + sale.discount,
      }
      return result
    }, {} as Record<UserGender, PrivateSaleItem>)

    const privateSale: PrivateSaleItem = salesMap[gender] || salesMap['UNISEX']

    return {
      privateSaleList,
      privateSale,
    }
  }, [ data, gender ])

  return {
    privateSaleList,
    privateSale,
    gender,
    isFetching,
  }
}


export default usePrivateSale
