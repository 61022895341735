export default {
  CURATED: {
    title: {
      en: 'Curated Collection',
    },
    description: {
      en: 'Tap into a mood or moment with these collections of hand-selected scents.',
    },
  },
  CAPSULE: {
    title: {
      en: 'Capsule Collection',
    },
    description: {
      en: 'Whether you want to throw a vibe, channel warmer climes, or complement an occasion, these collections are packed with scents that fit the bill.',
    },
  },
}
