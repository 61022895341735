import type * as Types from '../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type CatalogueChildrenQueryVariables = Types.Exact<{
  catalogueId: Types.Scalars['Long']['input'];
}>;


type CatalogueChildrenQueryPayload = { __typename?: 'Query', catalogue: { __typename?: 'CataloguePayload', data: { __typename?: 'Catalogue', id: number, name: string, children: Array<{ __typename?: 'Catalogue', id: number, name: string, pageTitle: string | null, products: { __typename?: 'CatalogueProductsData', count: number } }> } | null } };


 const CatalogueChildrenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CatalogueChildren"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"catalogueId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Long"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"catalogue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"catalogueId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"catalogueId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"catalogueTreeKey"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"appType"},"value":{"kind":"EnumValue","value":"WEB"}},{"kind":"ObjectField","name":{"kind":"Name","value":"treeName"},"value":{"kind":"StringValue","value":"MainCatalog","block":false}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"children"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"pageTitle"}},{"kind":"Field","name":{"kind":"Name","value":"products"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<CatalogueChildrenQueryPayload, CatalogueChildrenQueryVariables>;


export type CatalogueChildrenVariables = CatalogueChildrenQueryVariables;
export type CatalogueChildrenPayload = CatalogueChildrenQueryPayload;

// remove fragments duplicates
// @ts-ignore
CatalogueChildrenDocument['definitions'] = [ ...new Set(CatalogueChildrenDocument.definitions) ];

export default CatalogueChildrenDocument;