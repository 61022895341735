import { useQuery, type WatchQueryFetchPolicy } from 'apollo-client'

import catalogueProductsQuery, { type CatalogueProductsVariables } from './graph/catalogueProducts.graphql'


export type UseCatalogueProductsProps = {
  variables: CatalogueProductsVariables
  skip?: boolean
  ssr?: boolean
  notifyOnNetworkStatusChange?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
}

const useCatalogueProducts = ({ variables, skip, ssr, notifyOnNetworkStatusChange, fetchPolicy }: UseCatalogueProductsProps) => {
  const { data, isFetching, fetchMore } = useQuery(catalogueProductsQuery, {
    variables,
    fetchPolicy: fetchPolicy || 'cache-first',
    skip,
    ssr,
    notifyOnNetworkStatusChange,
  })

  return {
    data: data?.catalogue?.data?.products,
    fetchMore,
    isFetching,
  }
}


export default useCatalogueProducts
