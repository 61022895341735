import { useMemo } from 'react'
import { useQuery } from 'apollo-client'
import dayjs from 'date'
import type { UserGender } from 'typings/graphql'

import fomQuery, { type FomListVariables, type FomListPayload } from './graph/fom.graphql'


export const getFormattedCurrentBeforeDate = () => {
  let currentDate = dayjs()

  if (currentDate.date() >= 23) {
    currentDate = currentDate.add(1, 'month')
  }

  return currentDate.format('YYYY-MM')
}

export const modifyPOMData = (item: FomListPayload['productsOfMonth']['data'][number], gender: UserGender) => ({
  type: gender === 'MALE' ? 'cologne' as const : 'perfume' as const,
  kind: item.kind,
  month: item.month,
  monthName: item.monthName,
  year: item.year,
  wave: item.wave,
  productId: item.product.id,
  url: item.product.url,
  name: item.product.name,
  brand: item.product.brandInfo.name,
  productTitle: item.details?.product?.title,
  brandStory: item.details?.brand?.text,
  brandLogo: item.details?.brand?.logo,
  quote: item.details?.quote,
  video: item.details?.video,
  image: item.product?.rebrandImage || item.product?.image,
  listImage: item.details?.listImage || item.details?.image,
  bannerImage: item.details?.image,
  bannerMobileImage: item.details?.mobileImage,
  product: item.product,
})

export type UseFOMProps = {
  kind?: FomListVariables['input']['kind']
  gender?: UserGender
  months?: number
  date?: string
  skip?: boolean
  withDetails?: boolean
  withProductDetailed?: boolean
}

export const useFOM = ({ kind = 'PERFUME', gender, months = 12, date, skip, withDetails = false, withProductDetailed = false }: UseFOMProps) => {
  // shift to next month in getFormattedCurrentBeforeDate is incorrect,
  // correct behavior for current FOM is NOT set up "before" field at all -
  // (backend will return actual data - FOM works from the first day of month to the last day, 23th is a legacy)
  // but we keep it until bus.dev has reconfigured their marketing campaigns
  // TODO ping bus.dev about campaigns and fix logic - added on 28.06.2022 by sonatskiy
  const before = date || getFormattedCurrentBeforeDate()

  const { data, error, isFetching } = useQuery(fomQuery, {
    variables: {
      input: {
        gender,
        before,
        months,
        kind,
      },
      withDetails,
      withProductDetailed,
    },
    skip,
  })

  const items = useMemo(() => {
    if (!data?.productsOfMonth?.data) {
      return null
    }

    // value is read-only, so we need to copy the array
    return data.productsOfMonth.data
      .filter((item) => Boolean(item.product))
      .map((item) => modifyPOMData(item, gender))
      .reverse()
  }, [ data, gender ])

  return {
    items,
    error,
    isFetching,
  }
}

export type UseCurrentFOM = ReturnType<typeof useCurrentFOM>

export type UseCurrentFOMProps = Pick<UseFOMProps, 'kind' | 'gender' | 'skip' | 'withDetails'>

export const useCurrentFOM = ({ kind, gender, skip, withDetails }: UseCurrentFOMProps) => {
  const { items, error, isFetching } = useFOM({ kind, gender, months: 1, skip, withDetails })

  return {
    currentFOM: items?.[0],
    error,
    isFetching,
  }
}
