import { useMemo } from 'react'
import { useQuery } from 'apollo-client'

import catalogueStructureQuery from './graph/catalogueStructure.graphql'
import type { CatalogueStructurePayload } from './graph/catalogueStructure.graphql'


type CataloguePayloadData = CatalogueStructurePayload['catalogue']['data']
type CatalogueBaseElement = Omit<CataloguePayloadData['children'][number]['children'][number], 'children'> & { children: CatalogueBaseElement[] }

const flatCatalogue = (data: CatalogueBaseElement[]): Record<string, number> => {
  if (!data) {
    return null
  }

  return data.reduce((result, child) => {
    result[child.name] = child.id

    if ('children' in child) {
      return {
        ...result,
        ...flatCatalogue(child.children),
      }
    }

    return result
  }, {})
}

export type UseCatalogueStructureProps = {
  skip?: boolean
}

const useCatalogueStructure = ({ skip = false }: UseCatalogueStructureProps) => {
  const { data, isFetching } = useQuery(catalogueStructureQuery, {
    fetchPolicy: 'cache-first',
    skip,
    context: {
      ssrCache: true,
    },
  })

  // top level structure is object: {
  //   name: "Main"
  //   children: [
  //     { name: "subscription", children: [...] },
  //     { name: "shop", children: [...] }
  //   ],
  // }
  // ATTN now it maps catalogue name to its id, change if you need more data
  const flatStructure = useMemo(() => flatCatalogue(data?.catalogue?.data?.children), [ data ])

  return {
    flatStructure,
    isFetching,
  }
}


export default useCatalogueStructure
