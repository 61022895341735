import { useMemo } from 'react'
import { useApolloClient } from 'apollo-client'

import trackedProductQuery from './graph/trackedProduct.graphql'


type UseTrackedProductParams = {
  productUid: string
}

const useTrackedProduct = ({ productUid }: UseTrackedProductParams) => {
  const client = useApolloClient()

  const trackedProduct = useMemo(() => {
    const data = client.readFragment({
      id: client.cache.identify({ __typename: 'Product', uid: productUid }),
      fragment: trackedProductQuery,
    })

    if (!data) {
      return
    }

    const { tradingItems, brandInfo: { name: productBrand }, ...productData } = data

    const volume = tradingItems?.items?.[0]?.volume

    return {
      productBrand,
      productVolume: volume ? `${volume.volume} ${volume.unit}` : '',
      ...productData,
    }
  }, [ client, productUid ])

  return {
    trackedProduct,
  }
}

export default useTrackedProduct
