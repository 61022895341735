import { useQuery } from 'apollo-client'

import type { ProductsVariables } from './graph/products.graphql'
import productsQuery from './graph/products.graphql'


export type UseProductsProps = {
  variables: ProductsVariables
  skip?: boolean
  ssr?: boolean
  notifyOnNetworkStatusChange?: boolean
}

const useProducts = ({ variables, skip, ssr, notifyOnNetworkStatusChange }: UseProductsProps) => {
  const { data, isFetching, fetchMore } = useQuery(productsQuery, {
    variables,
    fetchPolicy: 'cache-first',
    skip,
    ssr,
    notifyOnNetworkStatusChange,
  })

  return {
    products: data?.productList?.data,
    isFetching,
    fetchMore,
  }
}


export default useProducts
